<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <!-- 催办 -->
      <ul class="form-ul">
        <!-- <li class="form-ul-li">
          <p class="li-title">日期：</p>
          <DatePicker :value='date'
                      type="date"
                      style="width: 100%"
                      @on-change="onChangeDate"></DatePicker>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">催办描述：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="催办描述" />
        </li>
      </ul>

    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '催办'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      remark: '',
      // date: ''
    }
  },
  methods: {
    // 选择日期
    onChangeDate (date) {
      this.date = date
    },
    // 确定按钮
    onClickConfirm () {
      let params = {
        caseIds: this.dataId,
        remark: this.remark
      }
      this.loading = true
      this.$http.urgePatrolCase(params).then(res => {
        this.$Message.info(res.message)
        if (res.code === 200) {
          this.loading = false
          this.$emit('onClickConfirm')
          this.$emit('onChange', false)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.remark = ''
      // this.date = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form-minheight {
  height: 300px;
}
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>