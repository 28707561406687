<template>
  <Modal :value="visible"
         :title="title+type"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <!-- 指派 / 改派 -->
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>指派：</p>
          <MyTreeSelect :value="assign"
                        placeholder="指派对象"
                        clearable
                        :data="userTreeArr"
                        search
                        :disabled="automatic"
                        @onSelectChange="onChangeAssign">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"></p>
          <Checkbox label="automatic"
                    v-model="automatic">自动指派</Checkbox>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>紧急程度：</p>
          <Select v-model="level"
                  placeholder="紧急程度"
                  @on-change="onChangeWorkOrderLevel">
            <Option v-for="item in levelArr"
                    :value="item.casesetLevelId"
                    :key="item.casesetLevelId">{{item.name}}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限时：</p>
          <Input v-model="time"
                 placeholder="限时"
                 readonly
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">抄送：</p>
          <MyTreeSelect :value="CC"
                        placeholder="抄送人"
                        clearable
                        :data="userTreeArr"
                        search
                        checked
                        @onCheckChange="onChangeCC">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"></p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="sendSms">短信推送</Checkbox>
        </li>
        <!-- <li class="form-ul-li">
          <p class="li-title">日期：</p>
          <DatePicker :value='date'
                      type="date"
                      style="width: 100%"
                      @on-change="onChangeDate"></DatePicker>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">分派备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="分派备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect'
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '问题'
    },
    visible: Boolean,
    type: String,
    item: null,
    lnglat: Object
  },
  data () {
    return {
      loading: false,
      assignId: '',
      assign: '',  //指派
      automatic: false, // 自动指派
      level: '', //紧急程度
      time: '',   //限时
      CCId: '',
      CC: '',   //抄送
      sendSms: false,  //短信推送
      // date: '',
      remark: '',   //分派备注
    }
  },
  watch: {
    item (newVal) {
      if (newVal) {
        let newKeyMap = this.$swapKeyValue(this.$store.state.patrolMaintain.patrolCaseKeyMap)
        let newData = this.$keyMapNew(newVal, newKeyMap)
        for (const i in newData) {
          this[i] = newData[i]
        }
        this.sendSms = newData.sendSms == 1
      }
    },
  },
  computed: {
    ...mapGetters({
      'levelArr': 'getWorkOrderLevelAllList',
      'userTreeArr': 'getUserTreeList',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateWorkOrderLevelAllList',
      'updateUserTreeList'
    ]),
    init () {
      // patrolCase已调用，防重复调用
      // this.updateWorkOrderLevelAllList()
      // this.updateUserTreeList()
    },
    // 修改指派对象
    onChangeAssign (data) {
      if (data.type === 'user') {
        this.assignId = data.id
        this.assign = data.name
      }
    },
    onChangeWorkOrderLevel (i) {
      if (i) {
        this.time = this.levelArr[i - 1].time;
      }
    },
    // 修改抄送人
    onChangeCC (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.CCId = idArr.join()
      this.CC = valueArr.join()
    },
    // 选择日期
    onChangeDate (date) {
      this.date = date
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.automatic) {
        if (!this.assignId) return this.$Message.info('请选择指派人')
      }
      if (!this.level) return this.$Message.info('请选择紧急程度')
      if (!this.time) return this.$Message.info('请输入限时分钟')
      if (this.automatic) {
        // 自动指派时调用自动指派的接口
        this.$http.automaticAssignPatrolCase(this.lnglat).then(res => {
          let data = res.result
          if (!data.value) {
            this.automatic = false
            this.$Message.info('暂无指派人可用')
          } else {
            this.assignId = data.value
            this.assign = data.name
            this.assignPatrolCase()
          }
        })
      } else {
        this.assignPatrolCase()
      }
    },
    assignPatrolCase () {
      let params = {
        id: this.id,
        assignId: this.assignId,
        level: this.level,
        time: this.time,
        CCId: this.CCId,
        sendSms: this.sendSms ? 1 : 0,
        remark: this.remark
      }
      this.loading = true
      if (this.type === '指派') {
        this.$store.dispatch('assignPatrolCase', params).then(res => {
          this.loading = false
          this.$emit('onClickConfirm')
          this.$emit('onChange', false)
        }).catch(err => {
          this.loading = false
        })
      } else {
        this.$store.dispatch('reassignmentPatrolCase', params).then(res => {
          this.loading = false
          this.$emit('onClickConfirm')
          this.$emit('onChange', false)
        }).catch(err => {
          this.loading = false
        })
      }
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:item', '')
        this.$emit('update:lnglat', {})
        this.resetData()
      }
    },
    resetData () {
      this.assignId = ''
      this.assign = ''
      this.level = ''
      this.time = ''
      this.CCId = ''
      this.CC = ''
      this.sendSms = false
      // this.date = ''
      this.remark = ''
      this.automatic = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-minheight {
  height: 300px;
}
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>